<template>
    <div class="home">
        <div class="content" style="padding-top: 1px;">
            <p class="title" @click="newConsole">首页</p>

            <div class="wallet">
                <van-row class="links">
                    <van-col span="12" class="lefter">
                        <a class="link">
                            钱包
                            <!-- <span class="iconfont icon-a-payicon_refresh_huaban1fuben86"></span> -->
                        </a>
                    </van-col>
                    <van-col span="12" class="righter" v-if="userInfo.SELL_TRANS">
                        <a class="link" @click="router.push({ name: 'SellTransSell', params: { sellTrans: userInfo.SELL_TRANS } })">
                            转账
                            <span class="iconfont icon-a-payicon_trans"></span>
                        </a>
                    </van-col>
                </van-row>

                <p class="wallet-2">
                    <span>$</span>
                    <span>{{ userInfo.ACC_BAL }}</span>
                </p>

                <p class="wallet-3" @click="clipboardText('.wallet-3')" :data-clipboard-text="userInfo.KPAY_ADDR">
                    <span class="address">钱包地址：{{ userInfo.KPAY_ADDR }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben23" style="font-size: 24px; color:#3366EE; vertical-align: middle;"></span>
                </p>
            </div>

            <div class="status">
                <div class="left">
                    <p>{{ userInfo.ACC_BAL_SALE }}</p>
                    <p>可售余额</p>
                </div>
                <div class="mid">
                    <p>{{ userInfo.SALE_BAL }}</p>
                    <p>卖单余额</p>
                </div>
                <div class="right">
                    <p>{{ userInfo.TRADE_BAL }}</p>
                    <p>交易中</p>
                </div>
            </div>

            <div class="deal">
                <div class="buy" @click="toJump('/Buy')">
                    <img :src="require('@/assets/img/buy.png')" />
                    <span>我要买</span>
                </div>

                <div class="sell" @click="toJump('/Sell')">
                    <img :src="require('@/assets/img/sell.png')" />
                    <span>我要卖</span>
                </div>
            </div>

            <div class="details">
                <div class="details-top">
                    <div @click="router.push('/MyDeity')">
                        <span class="iconfont icon-a-iconpay_huaban1"></span>
                        <p>我的挂单</p>
                    </div>

                    <div @click="router.push('/MyOrder')">
                        <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                        <p>我的订单</p>
                    </div>

                    <div @click="router.push('/Wallet')">
                        <span class="iconfont icon-a-iconpay_huaban1fuben5"></span>
                        <p>钱包记录</p>
                    </div>
                </div>

                <div class="details-bottom">
                    <div @click="toJump('/BankInformation')">
                        <span class="iconfont icon-a-iconpay_huaban1fuben6"></span>
                        <p>收付款信息</p>
                    </div>
                    <div @click="router.push('/Help')">
                        <span class="iconfont icon-a-iconpay_huaban1fuben7"></span>
                        <p>帮助</p>
                    </div>
                    <div @click="router.push('/Service')">
                        <span class="iconfont icon-a-iconpay_huaban1fuben8">
                            <span class="red" v-if="userInfo.SERVICEMSG_IND === 1"></span>
                        </span>
                        <p>客服</p>
                    </div>
                </div>
            </div>

            <div class="slideshow" style="height: 19.2vh;">
                <van-swipe :show-indicators="false">
                    <van-swipe-item v-for="item in bannerList" :key="item.ID">
                        <div>
                            <img class="banner-img" :src="item.src" @click="imgClick(item)" />
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getIndexBanner } from '@/api'
import { Dialog, Toast } from 'vant'
import { GOPAY_USERINFO, clipboardText, GOPAY_APPID } from '@/assets/js/common.js'
import VConsole from 'vconsole'
import BScroll from '@better-scroll/core'

export default {
    name: 'Home',
    setup() {
        const state = reactive({
            num: 0,
            userInfo: {
                ACC_BAL: 0,
                ACC_BAL_SALE: 0,
                ID: 0,
                ISSET_DRAWPASS: 0,
                ISSET_REALNAME: 0,
                KPAY_ADDR: '',
                NICK_NAME: '',
                SALE_BAL: 0,
                SELL_TRANS: 0, // 是否开启商户互转
                TRADE_BAL: 0,
                USER_NAME: '',
                USER_PIC: '',
                USER_TYPE: ''
            },
            bannerList: [],
            BScroll: null
        })

        const router = useRouter()
        const store = useStore()

        // 3 秒之内如果连续点击 logo 10次就弹出调试模式
        const newConsole = () => {
            state.num++
            if (state.num === 10) {
                window.vConsole = new VConsole()
            }

            let t = setTimeout(() => {
                state.num = 0
                clearTimeout(t)
            }, 3000)
        }

        // 首页 Banner 图
        getIndexBanner().then(res => {
            let data = res.data

            data.forEach(item => {
                item.src = store.state.fileConfig + item.BANNER_PICURL
            })

            state.bannerList = data
        })

        const initBScroll = () => {
            state.BScroll = new BScroll('.home', {
                click: true,
                tap: true
            })
        }

        onMounted(() => {
            initBScroll()
            setTimeout(() => {
                state.BScroll.refresh()
            }, 400)
            // 获取个人信息：实时更新，所以每次都要重新获取
            localStorage.removeItem(GOPAY_USERINFO)
            store.dispatch('getUserInfo').then(() => {
                state.userInfo = store.state.userInfo
            })
        })

        const toJump = path => {
            if (state.userInfo.ISSET_REALNAME === 0 && state.userInfo.USER_TYPE === '0') {
                Toast.fail('请先进行实名认证')
                router.push('/RealName')
            } else if (path === '/Buy' && state.userInfo.BUY_ENABLE === 0) {
                Toast.fail('没有权限操作')
            } else if (path === '/Sell' && state.userInfo.SELL_ENABLE === 0) {
                Toast.fail('没有权限操作')
            } else {
                router.push(path)
            }
        }

        const imgClick = item => {
            if (item.BANNER_LINKURL) {
                window.open(item.BANNER_LINKURL, '_blank')
            } else {
                Dialog.alert({
                    message: item.BANNER_DESC
                })
            }
        }
        return {
            ...toRefs(state),
            router,
            newConsole,
            clipboardText,
            toJump,
            imgClick
        }
    }
}
</script>

<style lang="scss" scoped>
.van-swipe-item {
    text-align: center;
    div {
        width: 96%;
        border-radius: 10px;
        overflow: hidden;
        margin: auto;
    }
}
.home {
    overflow: auto;
    //background: linear-gradient(to bottom, #6d88fb 0%, #6d88fb 28%, #ffffff 28%, #ffffff 100%);
    background: linear-gradient(to bottom, #3366ee 0, #3366ee 165px, #ffffff 165px, #ffffff 300px);
    box-sizing: border-box;
    padding: 0 w(15);

    .title {
        color: #ffffff;
        font-size: 17px;
        line-height: 22px;
        margin-top: h(20);
        text-align: center;
    }

    .wallet {
        margin-top: h(12);
        box-sizing: border-box;
        height: h(147);
        padding: h(20) w(15);
        color: #4f4f4f;
        background: #ffffff;
        border-radius: 20px 20px 0 0;

        .links {
            border: #f00 0px solid;
            .lefter {
            }
            .righter {
                text-align: right;
            }
            .link {
                font-size: 16px;
                line-height: 16px;
                color: #6d88ff;
                .iconfont {
                    font-size: 24px;
                    vertical-align: middle;
                }
            }
        }

        .wallet-2 {
            font-size: 24px;
            line-height: 32px;
            margin: h(10) 0 h(14) 0;

            & span:last-child {
                font-size: 40px;
                line-height: 44px;
            }
        }

        .wallet-3 {
            font-size: 12px;
            line-height: 16px;

            .address {
                color: #4f4f4f;
                font-size: 12px;
                line-height: 16px;
                margin-right: w(16);
                vertical-align: text-bottom;
            }

            .iconfont {
                color: $skin_color;
                font-size: 20px;
            }
        }
    }

    .status {
        box-sizing: border-box;
        height: h(74);
        padding: h(10) 0;
        background: #fafafa;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-flow: row nowrap;
        text-align: center;

        .left {
            flex: 1;
        }
        .mid {
            border-left: 1px solid #bdbdbd;
            border-right: 1px solid #bdbdbd;
            flex: 1;
        }
        .right {
            flex: 1;
        }
    }

    .deal {
        box-sizing: border-box;
        height: h(80);
        display: flex;
        flex-flow: row nowrap;
        margin-top: h(20);
        padding: h(10) 0;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(109, 136, 251, 0.2);
        border-radius: 12px;
        text-align: center;

        div {
            flex: 1;

            img {
                width: h(60);
                height: h(60);
                margin-right: w(8);
                vertical-align: middle;
            }

            span {
                font-size: 14px;
                line-height: 18px;
                color: #000000;
            }
        }
    }

    .details {
        span {
            color: $skin_color;
            font-size: 30px;
        }

        .details-top,
        .details-bottom {
            box-sizing: border-box;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: center;
            margin-top: h(20);

            div {
                flex: 1;

                p {
                    font-size: 12px;
                    line-height: 16px;
                    color: #4f4f4f;
                }
            }
        }

        .icon-a-iconpay_huaban1fuben8 {
            position: relative;
        }
        .red {
            position: absolute;
            width: 10px;
            height: 10px;
            right: -10px;
            top: 0;
            border-radius: 50%;
            background: red;
        }
    }

    .slideshow {
        margin-top: h(35);

        .banner-img {
            height: h(120);
            width: 100%;
        }
    }
}
</style>
